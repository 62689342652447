import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IntLicensesService } from '../../services/int-licenses.service';
import { IntLicenseVm, IntLicenseDetailsDto, IntLicenseDto } from '../../models/int-license';
import * as moment from 'moment';
import { Gender } from 'src/app/core/models/enums/gender';
import { LoadingService, LoaderType } from 'src/app/shared/services/loading';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'src/app/core/models/enums/language';
import * as _ from 'lodash';
import { FileLoaderService } from 'src/app/shared/services/file-loader.service';
import { PrintObject } from 'src/app/core/models/enums/print-object';
import { IntLicensePrintComponent } from '../../components/int-license-print/int-license-print.component';
import { ConfirmService } from 'src/app/shared/services/modal.service';
import { DeleteModal, ShipmentModal, UpdateModal } from 'src/app/shared/models/modal';
import { Role } from 'src/app/core/models/enums/role';
import { AuthService } from 'src/app/core/auth/auth.service';
import { CommitmentPrintComponent } from '../../components/commitment-print/commitment-print.component';
import { PrintLogDto } from '../../../_shared/models/issuing.models';
import { Utils } from 'src/app/shared/services/utils';
import { utils } from 'protractor';
import { StaticConfig } from 'src/app/features/lookups/services/config';
import { ConfigObject } from 'src/app/core/models/enums/config-object';
import { has, rest } from 'lodash';
import { CreateOrderUPSCommand, ELicenseOrderStatus } from '../../models/e-license';
import { ELicensesService } from '../../services/e-licenses.service';
import { ModalDismissReasons, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { convertELicenseOrderStatusToLocale } from '../../models/order-status-locale';

@Component({
  selector: 'mac-view-int-license',
  templateUrl: './view-int-license.component.html',
  styleUrls: ['./view-int-license.component.scss'],
  providers: [IntLicensesService, ELicensesService, FileLoaderService]

})


export class ViewIntLicenseComponent implements OnInit {
  cashedVm: IntLicenseVm;
  dto: IntLicenseDetailsDto = new IntLicenseDetailsDto();
  moment = moment;
  lang: Language;
  dateFormat = 'L';
  qRCodeImg: string;
  qRCodeUrl: string = '';
  closeResult = '';
  // _personalPhoto: string;
  // _passportPhoto: string;
  // _licensePhoto: string;
  confirmed: boolean;
  //_baseUrl = 'https://idl.mac.org.sa';
  maxPrintLimit: number;
  defaultImage = 'assets/images/default.jpg';

  convertELicenseOrderStatusToLocale = convertELicenseOrderStatusToLocale;

  @ViewChild('printElm') printElm: IntLicensePrintComponent;
  @ViewChild('comPrintElm') comPrintElm: CommitmentPrintComponent;

  get Gender() { return Gender };
  get PrintObject() { return PrintObject; };
  get Role() { return Role; };
  get Language() { return Language; };
  get Math() { return Math; };

  constructor(private route: ActivatedRoute,
    private intLicenseService: IntLicensesService,
    private eLicensesService: ELicensesService,
    private loader: LoadingService,
    private http: HttpClient,
    public translate: TranslateService,
    private fileLoader: FileLoaderService,
    private confirmService: ConfirmService,
    public auth: AuthService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private router: Router) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }
  printLabelByTrackingNumber(trackingNumber: string): void {
    const url = 'https://www.ups.com/ups.app/xml/Track';
    const headers = {
      'AccessLicenseNumber': '6DC092F026A4C6D0',
      'Username': 'MiddleEast01',
      'Password': 'Pa@1234554321',
      'Content-Type': 'application/json'
    };
    const body = {
      "UPSSecurity": {
        "UsernameToken": {
          "Username": "MiddleEast01",
          "Password": "Pa@1234554321"
        },
        "ServiceAccessToken": {
          "AccessLicenseNumber": "6DC092F026A4C6D0"
        }
      },
      "TrackRequest": {
        "InquiryNumber": trackingNumber
      }
    };
  
    this.http.post(url, body, { headers, responseType: 'json' }).subscribe(
      (response: any) => {
        const labelImage = response.TrackResponse.Shipment[0].Package[0].Label.GraphicImage;
        const labelFormat = response.TrackResponse.Shipment[0].Package[0].Label.ImageFormat.Code;
        
        // Save the label as a file or print it directly using appropriate methods
        const blob = this.b64toBlob(labelImage, `image/${labelFormat}`);
        saveAs(blob, `label.${labelFormat}`);
      },
      (error: any) => {
        console.error('Error printing label:', error);
      }
    );
  }
  containsNumbers(str: string): boolean {
    // Use a regular expression to check if the string contains any numbers
    const regex = /\d/;
    return regex.test(str);
  }
  b64toBlob(b64Data: string, contentType: string = '', sliceSize: number = 512): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  
  ngOnInit(): void {
    moment.locale('en-gb');
    document.querySelector('mat-card').classList.remove('overflow-hidden');
    this.lang = this.translate.currentLang == 'ar' ? Language.Arabic : Language.English;
    this.translate.onLangChange.subscribe(t => this.lang = t.lang == 'ar' ? Language.Arabic : Language.English);

    this.maxPrintLimit = Number(StaticConfig.getConfigKeyValuePairs(ConfigObject.IntLicenseMaxPrintLimit)[0].value);

    this.loader.load(LoaderType.Nav);
    const intLicenseId = this.route.snapshot.paramMap.get('id');
    if (intLicenseId) {
      this.intLicenseService.get(intLicenseId)
        .then(async result => {
          if (result.intLicense) {
            this.dto = result.intLicense;
            //console.log(result);
            //console.log(this.dto);

            if (this.dto.issued) {
              this.qRCodeUrl = Utils.generateQrCodeUrl(this.dto.hashedSerial);// `${this._baseUrl}/verify-license/${this.dto.hashedSerial}`;

              setTimeout(() => {
                this.qRCodeImg = document.getElementsByTagName('ngx-kjua')[0]
                  .getElementsByTagName('div')[0]
                  //.getElementsByTagName('img')[0].src;
                  .getElementsByTagName('canvas')[0].toDataURL("image/png");
              });
            }

            // await this.dto.attachments.forEach( att => {
            //   const file = await this.fileLoader.loadAsync(att);
            //   att = file ? file : this.defaultImage;
            //   ////console.log(att);
            // });

            this.dto.attachments[0] = (<any>await this.fileLoader.loadAsync(this.dto.attachments[0] as string)).changingThisBreaksApplicationSecurity;
            this.dto.attachments[1] = (<any>await this.fileLoader.loadAsync(this.dto.attachments[1] as string)).changingThisBreaksApplicationSecurity;
            this.dto.attachments[2] = (<any>await this.fileLoader.loadAsync(this.dto.attachments[2] as string)).changingThisBreaksApplicationSecurity;



            // this.dto.attachments.forEach((att, i) => {
            //   //const _attachments = this.attachments.controls[i];
            //   //for (let ctrl of Object.keys((<FormGroup>grp).controls))
            //   // if (ctrl.value instanceof Object)
            //   this.fileLoader.loadAsync(att).then(r => {
            //     //console.log(r);
            //     this.dto.attachments[i] = (<any>r).changingThisBreaksApplicationSecurity;
            //    // //console.log(this.dto.attachments);

            //   });//.changingThisBreaksApplicationSecurity;
            //   //  ctrl.setValue(ctrl.value.changingThisBreaksApplicationSecurity)
            // });


            this.displayPreview();
          } else this.router.navigate([`/${this.translate.currentLang}/not-found`]);
        })
        .finally(() => this.loader.load(LoaderType.Nav, false));
    }
  }

  isPrintable() {
    return !this.dto.issued
      || this.printObjectsCount(this.dto.printLogs, PrintObject.IntLicenseCard) < this.maxPrintLimit
      || this.printObjectsCount(this.dto.printLogs, PrintObject.IntLicenseSticker) < this.maxPrintLimit
  }

  isExpired(expiryDate) {
    return moment(expiryDate).isBefore(Date.now());
  }

  printObjectsCount(list: PrintLogDto[], printObject: PrintObject) {
    if (list)
      return list.filter(l => l.printObject == printObject).length;
  }

  print(printObject: PrintObject) {
    //console.log(printObject);
    if (printObject == PrintObject.IntLicenseCommitment) {
      //console.warn('try');
      this.comPrintElm.print(this.dto);
      return;

    }


    if (this.dto.accountId != this.auth.currentUser.accountId
      || !this.auth.currentUser.isInRole(Role.INT_LICENSES_UPDATE))
      return;

    //confirm if not yet issued
    if (!this.dto.issued) {
      let modal = this.confirmService.confirm(new UpdateModal({
        title: this.dto.serial.toString(),
        type: 'LIC_TITLE_LICENSE',
        action: '_ACTION_ISSUE',
        confirmQuestion: 'LIC_CONFIRM_ISSUE_CONFIRMATION_QUESTION',
        note: 'LIC_CONFIRM_ISSUE_NOTE',
        dangerNote: 'LIC_CONFIRM_ISSUE_DANGER_NOTE',
        submitText: '_ACTION_ISSUE'
      }));

      modal.then(async m => {
        if (m.indexOf('CONFIRMED') > -1)
          await this._print(printObject);
      });
    } else this._print(printObject);
  }

  async _print(printObject: PrintObject) {

    //check if print limit allowed
    if (this.printObjectsCount(this.dto.printLogs, printObject) < this.maxPrintLimit) {
      this.loader.load(LoaderType.Body);
      //request to server print
      try {
        const hash = await this.intLicenseService.print({ id: this.dto.id, printObject });
        //console.log(hash);
        if (hash && hash.value) {
          //get new hash if just issued
          //calcualte issue date in FE
          //calculate expiry date in FE
          this.dto.hashedSerial = hash.value
          //this.qRCodeUrl = `${this._baseUrl}/verify-license/${this.dto.hashedSerial}`;
          this.qRCodeUrl = Utils.generateQrCodeUrl(this.dto.hashedSerial);
          setTimeout(() => {
            this.qRCodeImg = document.getElementsByTagName('ngx-kjua')[0]
              .getElementsByTagName('div')[0]
              //.getElementsByTagName('img')[0].src;
              .getElementsByTagName('canvas')[0].toDataURL("image/png");
          });


          this.dto.issued = new Date();
          this.dto.expiryDate = moment(this.dto.issued).add(this.dto.validityYears, 'year').subtract(1, 'day').toDate();


          if (this.dto.isELicense) {
            this.dto.eLicenseOrder.status = ELicenseOrderStatus.LicenseIssued;
          }
        }

        //increment print objects
        this.dto.printLogs.push({ created: new Date(), printObject: printObject });

        //update ui
        await this.displayPreview();

        //open printing object
        await this.printElm.print(this.dto, printObject);

      }
      finally {
        this.loader.load(LoaderType.Body, false)
      }
    }
  }

  private async displayPreview() {
    const _issueDate = moment(this.dto.issued ? this.dto.issued : new Date()).format(this.dateFormat),
      _expiryDate = this.dto.issued ? moment(this.dto.expiryDate).format(this.dateFormat)
        : moment(new Date()).add(this.dto.validityYears, 'year').subtract(1, 'day').format(this.dateFormat);
    // _personalPhoto = await this.fileLoader.loadAsync(this.dto.personalPhoto);

    const gender = this.dto.gender == Gender.Male ? "Male" : "Female";

    let canvas1 = document.getElementById('lic-canvas') as HTMLCanvasElement;
    if (canvas1.getContext) {
      let ctx = canvas1.getContext('2d');

      const lic = new Image();

      lic.onload = () => {
        ctx.textAlign = 'left';
        ctx.font = 'bold 13px arial';
        ctx.drawImage(lic, 0, 0);

        let v = 75;
        const h = 210;
        const rh = 17.4;
        ctx.fillText(this.dto.name, h, v);
        ctx.fillText(moment(this.dto.dateOfBirth).format(this.dateFormat), h, v += rh);
        ctx.fillText(this.dto.nationalityTitles[Language.English], h, v += rh);
        ctx.fillText(this.dto.countryTitles[Language.English], h, v += rh);
        ctx.fillText(this.dto.passportID, h, v += rh);
        // ctx.fillText(Gender[this.dto.gender], h, v += rh);
        ctx.fillText(gender, h, v += rh);
        ctx.fillText(this.dto.bloodGroup, h, v += rh);
        ctx.fillText(`${this.dto.accordingToTitles[Language.English]} License`, h, v += rh);
        ctx.fillText(this.dto.permitClasses.join(', '), h, v += rh);
        // Display 'Expiry Date: ' in red color and bold
ctx.fillStyle = '#930407';
ctx.font = 'bold 13px arial';
ctx.fillText('Expiry Date: ', h + 60, v += rh + 20);

// Display _expiryDate in black color
ctx.fillStyle = 'black';
ctx.font = 'bold 13px arial';
ctx.fillText(_expiryDate, h + 60 + ctx.measureText('Expiry Date: ').width, v);
        ctx.fillText(_issueDate, 85, 49);

        ctx.font = 'bold 20px arial';
        ctx.fillText(Math.abs(this.dto.serial).toString(), 50, 29);

        let photo = new Image();
        photo.onload = () => {
          let x = 20, y = 66, width = 98, height = 133, radius = 5;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x + radius, y);
          ctx.lineTo(x + width - radius, y);
          ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
          ctx.lineTo(x + width, y + height - radius);
          ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
          ctx.lineTo(x + radius, y + height);
          ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
          ctx.lineTo(x, y + radius);
          ctx.quadraticCurveTo(x, y, x + radius, y);
          ctx.closePath();
          ctx.clip();

          ctx.drawImage(photo, x, y, width, height);
          ctx.restore();

          const qr = new Image();
          qr.onload = () => {
            ctx.drawImage(qr, 319, 92.4, 88, 88);
          }
          qr.src = this.dto.issued ? this.qRCodeImg : 'assets/images/qr.png';

          const ptt = new Image();
          ptt.onload = () => {

            ctx.save();
            ctx.globalAlpha = 0.25;
            const ptrn = ctx.createPattern(ptt, 'repeat');
            ctx.fillStyle = ptrn;
            ctx.fillRect(0, 0, 425, 265);
            ctx.restore();

            //draw thumb
            let canvas1Thumb = document.getElementById('lic-canvas-thumb') as HTMLCanvasElement;
            let thumbCtx = canvas1Thumb.getContext('2d');
            //thumbCtx.scale(0.2, 0.2);
            thumbCtx.drawImage(ctx.canvas, 0, 0);
          }
          //ptt.src = 'assets/images/sample-stamp.png';
        }
        photo.src = this.dto.attachments[0] as string;
        //photo.src = (<any>this.dto.attachments[0]).changingThisBreaksApplicationSecurity;
      }
      lic.src = "assets/images/license-empty.png";
    }

    let canvas2 = document.getElementById('stk-canvas') as HTMLCanvasElement;
    if (canvas2.getContext) {
      let ctx = canvas2.getContext('2d');

      let stk = new Image();
      stk.onload = () => {
        ctx.textAlign = 'left';
        ctx.font = 'bold 14px arial';
        ctx.drawImage(stk, 0, 0);

        let v = 162;
        const h = 152;
        const rh = 25.75;

        ctx.fillText(this.dto.name, h, v);
        ctx.fillText(moment(this.dto.dateOfBirth).format(this.dateFormat), h, v += rh);
        // ctx.fillText(Gender[this.dto.gender], h, v += rh);
        ctx.fillText(gender, h, v += rh);
        ctx.fillText(this.dto.nationalityTitles[Language.English], h, v += rh);
        ctx.fillText(this.dto.passportID, h, v += rh);
        ctx.fillText(this.dto.countryTitles[Language.English], h, v += rh);
        ctx.fillText(`${this.dto.accordingToTitles[Language.English]} License`, h, v += rh);
        ctx.fillText(this.dto.permitClasses.join(', '), h, v += rh);
        ctx.fillText(this.dto.bloodGroup, h, v += rh);
        ctx.fillText(_issueDate, h, 485);
        ctx.fillText(_expiryDate, h, 508);

        ctx.font = 'bold 25px arial';
        ctx.fillText(Math.abs(this.dto.serial).toString(), h, 120);

        const photo = new Image();
        photo.onload = () => {
          const x = 270, y = 355, width = 110, height = 152, radius = 5;

          ctx.rect(x, y, width, height);
          //ctx.stroke();
          ctx.drawImage(photo, x, y, width, height);

          const qr = new Image();
          qr.onload = () => {
            ctx.drawImage(qr, 287, 230, 95, 95);
          }
          qr.src = this.dto.issued ? this.qRCodeImg : 'assets/images/qr.png';

          const ptt = new Image();
          ptt.onload = () => {
            ctx.save();
            ctx.globalAlpha = 0.2;
            const ptrn = ctx.createPattern(ptt, 'repeat');
            ctx.fillStyle = ptrn;
            ctx.fillRect(0, 0, 425, 600);
            ctx.restore();

            //draw thumb
            let canvas2Thumb = document.getElementById('stk-canvas-thumb') as HTMLCanvasElement;
            let thumbCtx = canvas2Thumb.getContext('2d');
            //thumbCtx.scale(0.2, 0.2);
            thumbCtx.drawImage(ctx.canvas, 0, 0);
          }
         // ptt.src = 'assets/images/sample-stamp.png';
        }
        //photo.src = this._personalPhoto;
        photo.src = this.dto.attachments[0] as string;
        //photo.src = (<any>this.dto.attachments[0]).changingThisBreaksApplicationSecurity;

      }
      stk.src = "assets/images/sticker-empty.png";
    }
  }


  //eLicense
  //TODO: update order status after issuing (front-back)

  returnToCustomer = (id: string) => {
    // console.error("Not implemented");

    if (this.dto.accountId != this.auth.currentUser.accountId
      || !this.auth.currentUser.isInRole(Role.INT_LICENSES_UPDATE))
      return;

    let modal = this.confirmService.confirm(new UpdateModal({
      title: this.dto.eLicenseOrder.bill.trackingId,
      type: 'LIC_TITLE_LICENSE',
      action: 'إعادة طلب',
      confirmQuestion: 'هل تريد بالفعل إعادة طلب الرخصة الدولية رقم: ',
      note: `سيتم تحديث حالة الطلب إلى "معاد للإستكمال"، وسيتمكن مقدم الطلب من تعديل الطلب ثم إعادة التقديم مرة أخرى`,
      dangerNote: 'هذه العملية ستقوم بتجميد الطلب حتى يقوم مقدم الطلب بإتخاذ إجراء.',
      submitText: 'إعادة للإستكمال',
      submitClass: 'btn-warning',
      inputNote: 'ملاحظة الإعادة'
    }));

    modal.then(async m => {
      if (m.indexOf('CONFIRMED') > -1)
        await this._returnToCustomer(id, m[1].inputNote);
    });
  }

  private _returnToCustomer = (id, comment) => {
    console.warn(comment);

    this.loader.load(LoaderType.Body);
    //submit on server, and refresh UI
    this.eLicensesService.updateOrderStatus({ id, status: ELicenseOrderStatus.PaymentCompleted, comment })
      .then(result => {
        if (result) {
          console.warn(result);
          // const dto = this.dto;
          this.dto.eLicenseOrder.status = ELicenseOrderStatus.PaymentCompleted;

          if (!this.dto.eLicenseOrder.interactions)
            this.dto.eLicenseOrder.interactions = [];

          this.dto.eLicenseOrder.interactions.unshift({
            actor: 1,
            //TODO: time logged in local (BUG)
            created: moment().utc().toDate(),
            note: comment
          });

          // this.dto.eLicenseOrder.shippingAddress.shippingVendorTrackingNumber = comment;

          // this.dto = dto;
          // //console.log(this.dto);
        }
      })
      .catch(e => console.error(e))
      .finally(() => this.loader.load(LoaderType.Body, false));
  }

  reject = (id: string) => {
    // console.error("Not implemented");

    if (this.dto.accountId != this.auth.currentUser.accountId
      || !this.auth.currentUser.isInRole(Role.INT_LICENSES_UPDATE))
      return;

    let modal = this.confirmService.confirm(new UpdateModal({
      title: this.dto.eLicenseOrder.bill.trackingId,
      type: 'LIC_TITLE_LICENSE',
      action: 'رفض طلب',
      confirmQuestion: 'هل تريد بالفعل رفض طلب الرخصة الدولية رقم: ',
      note: `سيتم تحديث حالة الطلب إلى "مرفوض"، كما سيتم إعادة مبلغ ${this.dto.eLicenseOrder.bill.amount + ' ' + this.dto.eLicenseOrder.bill.total} إلى حساب العميل.`,
      dangerNote: 'هذه العملية لايمكن التراجع عنها، سيتم إشعار مقدم الطلب بالرفض',
      submitText: 'رفض',
      submitClass: 'btn-danger',
      inputNote: 'سبب الرفض'
    }));

    modal.then(async m => {
      if (m.indexOf('CONFIRMED') > -1)
        await this._reject(id, m[1].inputNote);
    });


  }


  private _reject = (id, comment) => {
    console.warn(comment);

    this.loader.load(LoaderType.Body);
    //submit on server, and refresh UI
    this.eLicensesService.updateOrderStatus({ id, status: ELicenseOrderStatus.Rejected, comment })
      .then(result => {
        if (result) {
          console.warn(result);
          // const dto = this.dto;
          this.dto.eLicenseOrder.status = ELicenseOrderStatus.Rejected;
          // this.dto.eLicenseOrder.shippingAddress.shippingVendorTrackingNumber = comment;

          // this.dto = dto;
          // //console.log(this.dto);
        }
      })
      .catch(e => console.error(e))
      .finally(() => this.loader.load(LoaderType.Body, false));
  }



  PayBranch = (id: string) => {

    if (this.dto.accountId != this.auth.currentUser.accountId
      || !this.auth.currentUser.isInRole(Role.INT_LICENSES_UPDATE))
      return;

    let modal = this.confirmService.confirm(new UpdateModal({
      title: `${this.dto.eLicenseOrder.bill.total} ${this.dto.eLicenseOrder.bill.currency}`,
      type: 'LIC_TITLE_LICENSE',
      action: 'تاكيد استلام مبلغ',
      confirmQuestion:  ` هل تريد بالفعل تأكيد استلام مبلغ طلب الرخصة رقم ${this.dto.eLicenseOrder.bill.couponDiscount} المبلغ المطلوب سداده هو `,
      note: 'سيتم تحديث وسيلة الدفع إلى "تم الدفع بالفرع".',
      dangerNote: 'هذه العملية لايمكن التراجع عنها، سيتم تغيير الحالة لمدفوعة ومطالبتك بالمبلغ',
      submitText: 'استلام المبلغ',
      inputNote: 'اسم المسئول عن استلام المبلغ'
    }));

    modal.then(async m => {
      if (m.indexOf('CONFIRMED') > -1)
        await this._PayBranch(id,' تم استلام المبلغ بالفرع بواسطة : '+ m[1].inputNote);
    });

  }
  private _PayBranch = (id, comment) => {

    this.loader.load(LoaderType.Body);
    //submit on server, and refresh UI
    this.eLicensesService.updateOrderStatus({ id, status: ELicenseOrderStatus.PaymentCompleted, comment })
      .then(result => {
        if (result) {
          console.warn(result);
          // const dto = this.dto;
          this.dto.eLicenseOrder.status = ELicenseOrderStatus.PaymentCompleted;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          const currentUrl = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(currentUrl);
          });
         // this.dto.eLicenseOrder.shippingAddress.shippingVendorTrackingNumber = comment;

          //this.dto.eLicenseOrder.status = ELicenseOrderStatus.LicenseIssued;

          // this.dto = dto;
          // //console.log(this.dto);
        }
      })
      .catch(e => console.error(e))
      .finally(() => this.loader.load(LoaderType.Body, false));
  }
  

  Pick = (id: string) => {

    if (this.dto.accountId != this.auth.currentUser.accountId
      || !this.auth.currentUser.isInRole(Role.INT_LICENSES_UPDATE))
      return;

    let modal = this.confirmService.confirm(new UpdateModal({
      title: this.dto.serial.toString(),
      type: 'LIC_TITLE_LICENSE',
      action: 'تحديث بيانات استلام',
      confirmQuestion: 'هل تريد بالفعل تسليم للعميل طلب رخصة دولية رقم: ',
      note: 'سيتم تحديث حالة الطلب إلى "تم الاستلام من الفرع".',
      dangerNote: 'هذه العملية لايمكن التراجع عنها، سيتم تغيير الحالة الاستلام من الفرع على مسؤليتك',
      submitText: 'تسليم',
      inputNote: 'اسم المسئول عن التسليم'
    }));

    modal.then(async m => {
      if (m.indexOf('CONFIRMED') > -1)
        await this._Pick(id,' تم التسليم بواسطة : '+ m[1].inputNote);
    });

  }
  private _Pick = (id, comment) => {

    this.loader.load(LoaderType.Body);
    //submit on server, and refresh UI
    this.eLicensesService.updateOrderStatus({ id, status: ELicenseOrderStatus.OrderDelivered, comment })
      .then(result => {
        if (result) {
          console.warn(result);
          // const dto = this.dto;
          this.dto.eLicenseOrder.status = ELicenseOrderStatus.OrderDelivered;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          const currentUrl = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(currentUrl);
          });
         // this.dto.eLicenseOrder.shippingAddress.shippingVendorTrackingNumber = comment;

          //this.dto.eLicenseOrder.status = ELicenseOrderStatus.LicenseIssued;

          // this.dto = dto;
          // //console.log(this.dto);
        }
      })
      .catch(e => console.error(e))
      .finally(() => this.loader.load(LoaderType.Body, false));
  }
  get ELicenseOrderStatus() {
    return ELicenseOrderStatus;
  }
  ship = (id: string) => {

    if (this.dto.accountId != this.auth.currentUser.accountId
      || !this.auth.currentUser.isInRole(Role.INT_LICENSES_UPDATE))
      return;

    let modal = this.confirmService.confirm(new UpdateModal({
      title: this.dto.serial.toString(),
      type: 'LIC_TITLE_LICENSE',
      action: 'تحديث بيانات شحنة',
      confirmQuestion: 'هل تريد بالفعل شحن طلب رخصة دولية رقم: ',
      note: 'سيتم تحديث حالة الطلب إلى "تم الشحن".',
      dangerNote: 'هذه العملية لايمكن التراجع عنها، سيتم إشعار مقدم الطلب برقم البوليصة، فضلاً التأكد من صحة البيانات قبل الضغط على زر تحديث',
      submitText: 'تحديث',
      inputNote: 'رقم بوليصة الشحن'
    }));

    modal.then(async m => {
      if (m.indexOf('CONFIRMED') > -1)
        await this._ship(id, m[1].inputNote);
    });

  }
  private _ship = (id, comment) => {

    this.loader.load(LoaderType.Body);
    //submit on server, and refresh UI
    this.eLicensesService.updateOrderStatus({ id, status: ELicenseOrderStatus.OrderShipped, comment })
      .then(result => {
        if (result) {
          console.warn(result);
          // const dto = this.dto;
          this.dto.eLicenseOrder.status = ELicenseOrderStatus.OrderShipped;
          this.dto.eLicenseOrder.shippingAddress.shippingVendorTrackingNumber = comment;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          const currentUrl = this.router.url;
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigateByUrl(currentUrl);
          });
          //this.dto.eLicenseOrder.status = ELicenseOrderStatus.LicenseIssued;

          // this.dto = dto;
          // //console.log(this.dto);
        }
      })
      .catch(e => console.error(e))
      .finally(() => this.loader.load(LoaderType.Body, false));
  }
 



  open(content) {
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        debugger;
        //this.closeResult = `Closed with: ${result.AddressLine.value} AddressLine: ${result.AddressLine.value}`;
        //alert(`Closed with: ${result.size} type: ${result.type} country: ${result.country}`);
        let _order = new CreateOrderUPSCommand();
        _order.order = JSON.stringify(result);
        //let hasEmpty = this.isEmpty(_order.order);
        //if (hasEmpty) {
        this._upsOrder(_order)
        // }
        //console.log(result);
      },
      (reason) => {
        debugger
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        // let currentUrl = this.router.url;
        // this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        //     this.router.navigate([currentUrl]);
        // });
      },
    );
  }
  isEmpty(obj) {
    debugger
    let jsonparse = JSON.parse(obj);
    if (jsonparse.AddressLine == '') { alert('AddressLine is required'); return false; }
    if (jsonparse.AttentionName == '') { alert('AttentionName is required'); return false; }
    if (jsonparse.Comments == '') { alert('Comments is required'); return false; }
    if (jsonparse.CommodityCode == '') { alert('CommodityCode is required'); return false; }
    if (jsonparse.CountryCode == '') { alert('CountryCode is required'); return false; }
    if (jsonparse.DeclarationStatement == '') { alert('DeclarationStatement is required'); return false; }
    if (jsonparse.Description == '') { alert('Description is required'); return false; }
    if (jsonparse.InvoiceDate == '') { alert('InvoiceDate is required'); return false; }
    if (jsonparse.InvoiceNumber == '') { alert('InvoiceNumber is required'); return false; }
    if (jsonparse.Name == '') { alert('Name is required'); return false; }
    if (jsonparse.Number == '') { alert('Number is required'); return false; }
    if (jsonparse.OriginCountryCode == '') { alert('OriginCountryCode is required'); return false; }
    if (jsonparse.PostalCode == '') { alert('PostalCode is required'); return false; }
    if (jsonparse.ProductWeight == '') { alert('ProductWeight is required'); return false; }
    if (jsonparse.PurchaseOrderNumber == '') { alert('PurchaseOrderNumber is required'); return false; }
    if (jsonparse.ReasonForExport == '') { alert('ReasonForExport is required'); return false; }
    if (jsonparse.StateProvinceCode == '') { alert('StateProvinceCode is required'); return false; }
    if (jsonparse.TermsOfShipment == '') { alert('TermsOfShipment is required'); return false; }
    if (jsonparse.UnitOfMeasurementType == '') { alert('UnitOfMeasurementType is required'); return false; }
    if (jsonparse.UnitOfMeasurementWeight == '') { alert('UnitOfMeasurementWeight is required'); return false; }
    //// if (jsonparse.customerbuildingNumber == '') { alert('customerbuildingNumber is required'); return false; }
    if (jsonparse.customercityName == '') { alert('customercityName is required'); return false; }
    if (jsonparse.customercontactPhoneNumber == '') { alert('customercontactPhoneNumber is required'); return false; }
    if (jsonparse.customercountry == '') { alert('customercountry is required'); return false; }
    if (jsonparse.customername == '') { alert('customername is required'); return false; }
    if (jsonparse.customerneighborhoodName == '') { alert('customerneighborhoodName is required'); return false; }
    if (jsonparse.customerpostalCode == '') { alert('customerpostalCode is required'); return false; }
    if (jsonparse.customerstreetName == '') { alert('customerstreetName is required'); return false; }
    if (jsonparse.pickuplocation == '') { alert('pickuplocation is required'); return false; }
    if (jsonparse.productname == '') { alert('productname is required'); return false; }
    return true;
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  private _upsOrder = (order: CreateOrderUPSCommand) => {
    console.warn(order);

    this.loader.load(LoaderType.Body);
    //submit on server, and refresh UI
    this.eLicensesService.createUpsShipment(order)
      .then(result => {
        if (result) {
          console.warn(result);
          debugger
          let modal = this.confirmService.confirm(new ShipmentModal({
            title: this.dto.serial.toString(),
            type: 'UPS Shipment Created',
            action: 'Success - ',
            confirmQuestion: 'Shippment is created against serail# ',
            note: 'Customer Name: ',
            dangerNote: '',
            submitText: 'Ok',
            cancelText: 'Ok'
          }));
          modal.then(() => { //console.log('When user closes'); }, () => {
            let currentUrl = this.router.url;
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate([currentUrl]);
            });
          })
          //         modal.then(async m => {
          //           debugger

          //           if (m.indexOf('CONFIRMED') > -1)
          //           {
          //             let currentUrl = this.router.url;
          //  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          //      this.router.navigate([currentUrl]);
          //  });
          //           }
          //           else {
          //             debugger
          //             let currentUrl = this.router.url;
          //             this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          //                 this.router.navigate([currentUrl]);
          //             });
          //           }
          //            // await this._print(printObject);
          //         });
          // const dto = this.dto;
          // this.dto.eLicenseOrder.status = ELicenseOrderStatus.OrderShipped;
          // this.dto.eLicenseOrder.shippingAddress.shippingVendorTrackingNumber = comment;

          //this.dto.eLicenseOrder.status = ELicenseOrderStatus.LicenseIssued;

          // this.dto = dto;
          // //console.log(this.dto);
        }
      })
      .catch(e => console.error(e))
      .finally(() => this.loader.load(LoaderType.Body, false));
  }


}
