import { Component, OnInit, ElementRef } from '@angular/core';
import { IntLicenseDetailsDto } from '../../models/int-license';
import { Utils } from 'src/app/shared/services/utils';
import { Language } from 'src/app/core/models/enums/language';
import { Gender } from 'src/app/core/models/enums/gender';
import { FileLoaderService } from 'src/app/shared/services/file-loader.service';
import { PrintObject } from 'src/app/core/models/enums/print-object';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'mac-int-license-print',
  templateUrl: './int-license-print.component.html',
  styleUrls: ['./int-license-print.component.scss'],
  providers: [FileLoaderService]
})
export class IntLicensePrintComponent {

  dto: IntLicenseDetailsDto;
  //personalPhoto: string;
  qRCodeUrl: string;
  printObject: PrintObject;

  get Language() { return Language; };
  get Gender() { return Gender };
  get PrintObject() { return PrintObject; };

  constructor(private elRef: ElementRef, private fileLoader: FileLoaderService) {
  }

  async print(dto: IntLicenseDetailsDto, printObject: PrintObject) {
    this.dto = dto;
    this.printObject = printObject;

    this.qRCodeUrl = Utils.generateQrCodeUrl(this.dto.hashedSerial);// `${this._baseUrl}/verify-license/${this.dto.hashedSerial}`;
    //this.personalPhoto = (<any>(await this.fileLoader.loadAsync(dto.attachments[0] as string))).changingThisBreaksApplicationSecurity;

    setTimeout(() => {
      const printHtmlContent = this.elRef.nativeElement.innerHTML;
      Utils.printHtml(printHtmlContent);
    }, 500);
  }
}
