export enum ELicenseOrderStatus {
    DraftDocument,
    PaymentCompleted,
    OrderPlaced,
    Rejected,
    Refunded,
    LicenseIssued,
    OrderShipped,
    OrderDelivered,
    OrderPicked,
    OrderNeddToPay,
}

export class ELicenseOrderDto {
    status: number;
    shippingAddress: ELicenseShippingAddressDto;
    plan: ELicensePlanDto;
    bill: ELicenseBillDto;
    interactions: ELicenseInteractionDto[]
}

export class ELicenseShippingAddressDto {
    id: string;
    countryId: number;
    cityName: string;
    neighborhoodName: string;
    streetName: string;
    buildingNumber?: number;
    postalCode: string;
    contactPhoneNumber: string;
    shippingVendor: string;
    shippingVendorTrackingNumber: string;
    shippingVendorTrackingUrl: string;
}

export class ELicensePlanDto {
    planId: number;
    validityYears: number;
}

export class ELicenseBillDto {
    
    total: number; //الاجمالى
    paid?: Date;
    refunded?: Date;
    paymentId: string;
    trackingId: string;//وسيلة الدفع
    currency: string;
    vat: number;
    amount: number;
    amountDiscount: number;
    shippingFees: number;
    shippingFeesDiscount: number;
    couponDiscount: number;//رقم الطلب
  
}

export class ELicenseInteractionDto {
    actor: number;
    created: Date;
    note: string;
}


export class UpdateELicenseOrderStatusCommand {
    id: string;
    status: ELicenseOrderStatus;
    comment?: string;
}

export class CreateOrderUPSCommand {
    order: string;
}